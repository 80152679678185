import React, { useEffect, useState, useRef } from "react";
import "./doctorCases.scss";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import {
  Button,
  Drawer,
  Menu,
  Modal,
  Popconfirm,
  Popover,
  Table,
  Tooltip,
} from "antd";
import moment from "moment";
import DoctorCaseService from "../../../services/Case/DoctorCase/doctorCase.service";
import CaseOverview from "../../../shared/components/CaseOverview";
import {
  copyCaseDetailsToClipboard,
  stopPropogateEvent,
} from "../../../shared/utils/eventUtils";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import {
  DownloadOutlined,
  CloudDownloadOutlined,
  FormOutlined,
  EllipsisOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import ReportForm from "../../../shared/components/ReportForm";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { hasDoctorAccessToCase } from "../../../shared/utils/accessUtils";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { formatStatus } from "../../../shared/utils/dataFormatConverter";
import { generatePath, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { Switch } from "antd";
import ProfileService from "../../../services/Profile/profile.service";
import { Doctor } from "../../../models/Doctor/doctor.model";
import { useLocation } from "react-router-dom";
import SharedCaseService from "../../../services/Shared/shared.service";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface DoctorCasesProps extends CaseReducerProps {}

function DoctorCases({ newCaseDetail, updatedCaseDetail }: DoctorCasesProps) {
  const location = useLocation();

  const [me, setMe] = useState<Doctor>();

  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [activeCase, setActiveCase] = useState<CaseDetail>();

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
    })
  );
  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleOnChange = (updatedValues: CaseFilterParams) => {
    // Update the form values with new inputs
    const newFormValues = Object.assign(new CaseFilterParams(), updatedValues);
    setFormValues(newFormValues);

    // Clear any existing debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set a new debounce timer
    debounceTimerRef.current = setTimeout(() => {
      // handleFetchCases(newFormValues); // Call the fetch function with the updated form values
    }, 500); // 500ms debounce delay
  };

  // Cleanup on component unmount to clear the debounce timer
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleReset = () => {
    const formValues = new CaseFilterParams();
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleToggleReportForm = () => setShowReportForm(!showReportForm);

  const handleCloseCaseOverview = () => {
    setActiveCase(undefined);
    setShowCaseOverview(false);
  };

  const history = useHistory();

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        event.preventDefault(); // Prevent default behavior if needed
        if (caseDetail?.id) {
          history.push(
            generatePath(AppRoutes.CASE_DETAIL, {
              caseId: caseDetail.id,
            })
          );
        }
      },
    };
  };

  // Initial data fetch with URL parameters
  useEffect(() => {
    const initialFilters = getInitialFiltersFromURL();
    setFormValues(initialFilters);
    handleFetchCases(initialFilters);

    ProfileService.getProfile(
      UserRoleEnum.DOCTOR,
      (doctor: Doctor) => {
        setMe(doctor);
      },
      () => {},
      () => {}
    );
  }, [location.search]); // Re-fetch when URL changes

  const getInitialFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const urlFilters: Partial<CaseFilterParams> = {};

    const paramParsers: Record<string, (value: string) => any> = {
      patientName: (v) => v,
      scanCenterId: (v) => (v ? Number(v) : undefined),
      doctorId: (v) => (v ? Number(v) : undefined),
      scanTypeId: (v) => (v ? Number(v) : undefined),
      bodyPartId: (v) => (v ? Number(v) : undefined),
      status: (v) => v,
      fromDate: (v) => v || undefined,
      toDate: (v) => v || undefined,
    };

    Object.keys(paramParsers).forEach((key) => {
      const value = searchParams.get(key);
      if (value !== null) {
        urlFilters[key as keyof CaseFilterParams] = paramParsers[key](value);
      }
    });

    const initialFilters = new CaseFilterParams();

    // If fromDate is not in URL, set it to today's date
    if (!urlFilters.fromDate) {
      // Assuming you want the date in YYYY-MM-DD format
      urlFilters.fromDate = moment().format("YYYY-MM-DD");
    }

    // If toDate is not in URL, set it to today's date
    if (!urlFilters.toDate) {
      // Assuming you want the date in YYYY-MM-DD format
      urlFilters.toDate = moment().format("YYYY-MM-DD");
    }

    return Object.assign(initialFilters, urlFilters);
  };

  // Update URL with current filter values
  const updateUrlParams = (values: CaseFilterParams) => {
    const searchParams = new URLSearchParams();

    // Add non-empty filter values to URL
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.set(key, String(value));
      }
    });

    // Update URL without page reload
    history.replace(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);

    updateUrlParams(formValues);

    DoctorCaseService.fetchDoctorCases(
      formValues,
      (caseDetails: CaseDetail[]) => {
        setCaseDetails(caseDetails);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleDownloadDetails = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      setDownloadZipLoading(true);
      DoctorCaseService.downloadDoctorCase(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadZipLoading(false);
        }
      );
    }
  };

  const handleSelfAssignCase = (caseDetail: CaseDetail) => {
    DoctorCaseService.selfAssignCase(
      caseDetail,
      (caseDetail: CaseDetail) => {
        const caseIndex = caseDetails.findIndex(
          (caseItem) => caseItem.id === caseDetail.id
        );
        if (caseIndex >= 0) {
          caseDetails[caseIndex] = caseDetail;
        }
        setCaseDetails([...caseDetails]);
      },
      () => {},
      () => {}
    );
  };

  const handleCaseAssignment = (checked: boolean, caseDetail: any) => {
    // If you need to pass the specific case detail, you'll need to adjust how you're handling this
    if (checked) {
      DoctorCaseService.RemoveCaseAssignment(
        caseDetail, // Assuming caseDetail is in scope or passed differently
        (caseDetail: CaseDetail) => {
          const caseIndex = caseDetails.findIndex(
            (caseItem) => caseItem.id === caseDetail.id
          );
          if (caseIndex >= 0) {
            const newCaseDetails = [...caseDetails];
            newCaseDetails[caseIndex] = caseDetail;
            setCaseDetails(newCaseDetails);
          }
        },
        () => {},
        () => {}
      );
    } else {
      DoctorCaseService.selfAssignCase(
        caseDetail,
        (caseDetail: CaseDetail) => {
          const caseIndex = caseDetails.findIndex(
            (caseItem) => caseItem.id === caseDetail.id
          );
          if (caseIndex >= 0) {
            caseDetails[caseIndex] = caseDetail;
          }
          setCaseDetails([...caseDetails]);
        },
        () => {},
        () => {}
      );
    }
  };

  const handleDownloadReport = (
    caseDetail: CaseDetail,
    caseType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    setActiveCase(caseDetail);
    setDownloadLoading(true);
    if (caseDetail?.id) {
      ReportService.downloadReport(
        caseType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setActiveCase(undefined);
          setDownloadLoading(false);
        }
      );
    }
  };

  const handleCaseShareLink = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      SharedCaseService.getCaseShareLink(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          copyCaseDetailsToClipboard(caseDetail, targetUrl);
        },
        () => {},
        () => {}
      );
    }
  };

  useEffect(() => {
    if (newCaseDetail) {
      caseDetails.unshift(newCaseDetail);
      setCaseDetails([...caseDetails]);
    }
  }, [newCaseDetail]);

  useEffect(() => {
    if (updatedCaseDetail) {
      const caseIndex = caseDetails.findIndex(
        (caseDetail) => caseDetail.id === updatedCaseDetail.id
      );
      if (caseIndex >= 0) {
        caseDetails[caseIndex] = updatedCaseDetail;
      } else {
        caseDetails.unshift(updatedCaseDetail);
      }
      setCaseDetails([...caseDetails]);
    }
  }, [updatedCaseDetail]);

  const columns = [
    {
      title: "Patient name",
      dataIndex: "patientName",
      key: "patientName",
      sorter: (a: any, b: any) => a.patientName.localeCompare(b.patientName),
      render: (patientName: string) => (
        <div className="admin-cases__patient-name">{patientName}</div>
      ),
    },
    {
      title: "Scan Center",
      dataIndex: "scanCenterName",
      key: "scanCenterName",
      sorter: (a: any, b: any) =>
        a.scanCenterName.localeCompare(b.scanCenterName),
    },
    {
      title: "Date Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),
      render: (date: string) => (
        <span>{date ? moment(date).format("DD-MMM-YYYY hh:mm A") : "NA"}</span>
      ),
    },
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
    },
    {
      title: "Body parts",
      dataIndex: "caseBodyParts",
      key: "caseBodyParts",
      render: (caseBodyParts: CaseBodyPart[]) => (
        <div>
          {caseBodyParts.map((bodyPart) => bodyPart.bodyPartName).join(", ")}
        </div>
      ),
    },
    {
      title: "Due Time",
      dataIndex: "dueDate",
      key: "dueDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (status: string, caseDetail: CaseDetail) => {
        return (
          <div
            className="doctor-cases__status-wrapper"
            onClick={stopPropogateEvent}
          >
            <span className={`doctor-cases__status ${status}`}>
              {formatStatus(status)}
            </span>
            {(status === CaseStatusEnum.UNASSIGNED ||
              status === CaseStatusEnum.ASSIGNED) && (
              <Tooltip
                title={
                  status === CaseStatusEnum.ASSIGNED
                    ? "Remove Assignment"
                    : "Self Assign Case"
                }
              >
                <Popconfirm
                  title="Are you sure?"
                  onConfirm={() =>
                    handleCaseAssignment(
                      status === CaseStatusEnum.ASSIGNED,
                      caseDetail
                    )
                  }
                  okText={
                    status === CaseStatusEnum.ASSIGNED
                      ? "Yes, Remove assignment"
                      : "Yes, Assign to me"
                  }
                  cancelText="No"
                  placement="bottom"
                >
                  <Switch
                    checked={status === CaseStatusEnum.ASSIGNED}
                    onChange={(checked) => {}}
                    className="ml-2"
                  />
                </Popconfirm>
              </Tooltip>
            )}
            {caseDetail?.status === CaseStatusEnum.ASSIGNED && (
              <Tooltip title="Generate Report">
                <Button
                  icon={<FormOutlined />}
                  className="ml-2"
                  onClick={() => {
                    setActiveCase(caseDetail);
                    handleToggleReportForm();
                  }}
                />
              </Tooltip>
            )}
            {caseDetail?.status === CaseStatusEnum.REPORTED && (
              <Tooltip title="Download Report">
                <Popover
                  destroyTooltipOnHide
                  overlayClassName="admin-cases__assignment-popover"
                  placement="bottomLeft"
                  content={
                    <div>
                      <Menu mode="vertical" className="border-none">
                        <Menu.Item
                          className="navbar-item m-0"
                          key="with_letterhead"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              true
                            );
                          }}
                        >
                          Download with Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="without_letterhead"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              false
                            );
                          }}
                        >
                          Download without Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="as_word"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.WORD,
                              false
                            );
                          }}
                        >
                          Download as Word
                        </Menu.Item>
                      </Menu>
                    </div>
                  }
                  title="Download Report"
                  trigger="click"
                >
                  <Button
                    loading={
                      activeCase?.id === caseDetail?.id && downloadLoading
                    }
                    icon={<DownloadOutlined />}
                    className="ml-2"
                  />
                </Popover>
              </Tooltip>
            )}
            {/* /* Commented for Future use */}
            {/* {hasDoctorAccessToCase(UserRoleEnum.DOCTOR, caseDetail) && ( */}
            <Tooltip title="Download Case">
              <Button
                loading={
                  activeCase?.id === caseDetail?.id && downloadZipLoading
                }
                icon={<CloudDownloadOutlined />}
                className="ml-2"
                onClick={() => {
                  handleDownloadDetails(caseDetail);
                }}
              />
            </Tooltip>
            <Tooltip title="Copy Share Link">
              <Button
                icon={<CopyOutlined />}
                className="ml-2 clickable-icon"
                onClick={() => {
                  Notification({
                    message: "Copied",
                    type: NotificationTypes.SUCCESS,
                  });
                  handleCaseShareLink(caseDetail);
                }}
              />
            </Tooltip>
            {/* )} */}
            {/* /* Commented for Future use */}
            <Tooltip title="More Case Details">
              <Button
                icon={<EllipsisOutlined />}
                className="ml-2"
                onClick={(event: any) => {
                  setActiveCase(caseDetail);
                  setShowCaseOverview(true);
                }}
              />
            </Tooltip>
          </div>
        );
      },
      width: 200,
    },
  ];

  return (
    <div className="doctor-cases">
      <AppCard>
        <CaseFilterForm
          isDoctor
          formValues={formValues}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onChange={handleOnChange}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={caseDetails}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            columns={columns}
          />
        )}
        <Modal
          destroyOnClose
          visible={showCaseOverview}
          className="primary-modal"
          onCancel={handleCloseCaseOverview}
          footer={null}
          maskClosable={false}
        >
          {activeCase?.id && (
            <CaseOverview
              activeCaseId={activeCase?.id}
              onCancel={handleCloseCaseOverview}
            />
          )}
        </Modal>
      </AppCard>

      <Drawer
        destroyOnClose
        title={null}
        width="70%"
        onClose={handleToggleReportForm}
        visible={showReportForm}
        maskClosable={false}
        footer={null}
      >
        {activeCase?.id && (
          <ReportForm
            onClose={handleToggleReportForm}
            caseDetailId={activeCase.id}
            onPublish={() => handleFetchCases(formValues)}
          />
        )}
      </Drawer>
    </div>
  );
}

export default CaseContainer(DoctorCases);
