import React, { useEffect, useState } from "react";
import "./attachmentUpload.scss";
import { ErrorMessage, Field } from "formik";
import Error from "../Error";
import { Row, Col } from "antd";
import { CloseOutlined, UploadOutlined } from "@ant-design/icons";
import { Attachment } from "../../../models/Attachment/attachment.model";
// import FileDetail from "../FileDetail";

interface AttachmentUploadProps {
  name: string;
  title?: string;
  placeholder: string;
  multiple?: boolean;
  accept: string;
  existingAttachments: Attachment[];
  newAttachments: File[];
  setFieldValue: Function;
  onUpload?: Function;
  onDeleteNewAttachment?: Function;
  onDeleteExistingAttachment?: Function;
}

function AttachmentUpload(props: AttachmentUploadProps) {
  let inputRef: any;

  const {
    name,
    title,
    placeholder,
    multiple,
    existingAttachments,
    onUpload,
    setFieldValue,
    newAttachments,
    accept,
    onDeleteNewAttachment,
    onDeleteExistingAttachment,
  } = props;

  const [newAttachmentUrls, setNewAttachmentUrls] = useState<string[]>([]);

  const handleClick = () => {
    inputRef?.click();
  };

  const handleChange = (e: any) => {
    if (multiple) {
      const files: File[] = Array.from(e.target.files);
      onUpload && onUpload(files);
      setFieldValue(name, [...newAttachments, ...files]);
    } else {
      setFieldValue(name, e.target.files[0]);
    }
  };

  useEffect(() => {
    if (newAttachments.length > 0) {
      const newAttachmentUrls: any = [];
      newAttachments.forEach((file: any) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          if (typeof reader.result === "string") {
            newAttachmentUrls.push(reader.result);
            setNewAttachmentUrls([...newAttachmentUrls]);
          }
        };
        reader.onerror = function (error) {};
      });
    } else {
      setNewAttachmentUrls([]);
    }
  }, [newAttachments]);

  const handleDeleteNewAttachment = (i: number) => {
    newAttachments.splice(i, 1);
    setFieldValue(name, [...newAttachments]);
    onDeleteNewAttachment && onDeleteNewAttachment(i);
  };

  return (
    <Field name={name}>
      {({ field, form: { touched, errors }, meta }: any) => (
        <div className="attachment-upload">
          {title && <div className="attachment-upload__title">{title}</div>}
          <Row className="attachment-upload__attachment-list" gutter={[15, 15]}>
            <Col span={24}>
              <div className="attachment-upload__input" onClick={handleClick}>
                <UploadOutlined className="mr-2" />
                {placeholder}
              </div>
              <input
                ref={(ref) => (inputRef = ref)}
                type="file"
                multiple={multiple}
                onChange={handleChange}
                accept={accept}
                style={{ display: "none" }}
              />
            </Col>
            {existingAttachments?.map((attachment: Attachment, i: number) => {
              return (
                <Col
                  span={6}
                  className="attachment-upload__img-wrapper"
                  key={i}
                >
                  <a href={attachment.imageSignedUrl} target="_">
                    <img
                      src={attachment.imageSignedUrl}
                      className="attachment-upload__img"
                      alt="Attachment"
                    />
                  </a>
                  <span
                    className="attachment-upload__close-button"
                    onClick={() => {
                      onDeleteExistingAttachment &&
                        onDeleteExistingAttachment(i);
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </Col>
              );
            })}
            {newAttachmentUrls?.map((attachmentUrl: string, i: number) => {
              return (
                <Col
                  span={6}
                  className="attachment-upload__img-wrapper"
                  key={i}
                >
                  {attachmentUrl.startsWith("data:image") ? (
                    <img
                      src={attachmentUrl}
                      className="attachment-upload__img"
                      alt="Attachment"
                    />
                  ) : null}
                  <span
                    className="attachment-upload__close-button"
                    onClick={() => {
                      handleDeleteNewAttachment(i);
                    }}
                  >
                    <CloseOutlined />
                  </span>
                </Col>
              );
            })}
          </Row>
          <ErrorMessage name={name}>
            {(message: string) => <Error message={message} />}
          </ErrorMessage>
        </div>
      )}
    </Field>
  );
}

export default AttachmentUpload;
