import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { generatePath } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import SharedCaseService from "../../../services/Shared/shared.service";

const SharedPageRedirect = () => {
  const history = useHistory();
  const location = useLocation();

  const handleGuestLogin = (caseDetailID: number, key: any) => {
    return new Promise<void>((resolve, reject) => {
      if (caseDetailID && key) {
        SharedCaseService.guestLogin(
          Number(caseDetailID),
          key,
          (token: string) => {
            try {
              localStorage.setItem("Authorization", token);
              resolve(); // Resolve the promise when localStorage is updated
            } catch (error) {
              reject(error); // Reject if there's any error while setting localStorage
            }
          },
          () => {
            reject(new Error('Failed to log in')); // Reject if the login fails
          },
          () => {
            reject(new Error('Failed to log in')); // Handle the error condition
          }
        );
      } else {
        reject(new Error('Invalid caseDetailID or key'));
      }
    });
  };
  

  useEffect(() => {
    const processGuestLogin = async () => {
      const searchParams = new URLSearchParams(location.search);
      const fullQueryString = location.search.substring(1);
  
      const params: any = {};
      fullQueryString.split('&').forEach(param => {
        const [key, value] = param.split('=');
        params[key] = decodeURIComponent(value).replace(/\?$/, '');
      });
  
      const key = params['key'] || searchParams.get('key');
      const caseId = params['case_id'] || searchParams.get('case_id');
  
      try {
        // Wait for the login process to complete
        await handleGuestLogin(caseId, key);
  
        // Now check for the token in localStorage
        const token = localStorage.getItem("Authorization");
        if (token) {
          history.push(
            generatePath(AppRoutes.CASE_DETAIL_GUEST, {
              caseId: caseId,
            })
          );
        } else {
          history.replace('/auth/login');
        }
      } catch (error) {
        console.error("Error during guest login:", error);
        history.replace('/auth/login');
      }
    };
  
    processGuestLogin();
  }, [history, location]);
  
  

  // Optionally render a loading spinner while redirecting
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="spinner">Authenticating...</div>
    </div>
  );
};

export default SharedPageRedirect;