import Axios from "axios";
import { CaseDetail } from "../../models/CaseDetail/caseDetail.model";

export const buildFormData = (formData: any, data: any, parentKey?: any) => {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    const value = data == null ? "" : data;

    formData.append(parentKey, value);
  }
};

export function convertJSONToFormData(data: any) {
  const formData = new FormData();

  buildFormData(formData, data);

  return formData;
}

export function formatStatus(status: string) {
  return status.split("_").join(" ");
}

export function formatFileNameFromCaseDetail(caseDetail: CaseDetail) {
  const patientId = caseDetail?.patientId ?? "";
  const patientName = caseDetail?.patientName ?? "";
  const scanTypeName = caseDetail?.scanTypeName ?? "";
  const bodyParts = caseDetail?.caseBodyParts
    ?.map((bodyPart) => bodyPart.bodyPartName)
    .join("_");
  return [patientId, patientName, scanTypeName, bodyParts].join("_");
}

export const uploadFileToS3 = async (file: File, signedUrl: string) => {
  return Axios.put(signedUrl, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
};
