import { CaseBodyPart } from "../CaseBodyPart/caseBodyPart.model";

import {
  serializable,
  alias,
  object,
  list,
  primitive,
  custom,
} from "serializr";
import { ScanCenterBranch } from "../ScanCenterBranch/scanCenterBranch.model";
import { Attachment } from "../Attachment/attachment.model";

export class CaseDetail {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("patient_id", primitive()))
  patientId?: string;

  attachments: File[] = [];

  @serializable(alias("case_attachments", list(object(Attachment))))
  caseAttachments?: Attachment[] = [];

  @serializable(alias("patient_name", primitive()))
  patientName?: string;

  @serializable(alias("scan_center_branch", object(ScanCenterBranch)))
  scanCenterBranch?: ScanCenterBranch;

  @serializable(alias("scan_center_branch_id", primitive()))
  scanCenterBranchId?: string;

  @serializable(alias("assigned_to_doctor_name", primitive()))
  assignedToDoctorName?: string;

  @serializable(alias("approved_by_admin", primitive()))
  approvedByAdmin?: string;

  @serializable(alias("approved_by_admin_id", primitive()))
  approvedByAdminId?: number;

  @serializable(alias("scan_type_id", primitive()))
  scanTypeId?: number;

  @serializable(alias("due_date", primitive()))
  dueDate?: string;

  @serializable(alias("scan_type_name", primitive()))
  scanTypeName?: string;

  @serializable(alias("created_at", primitive()))
  createdAt?: string;

  @serializable(alias("scan_center_id", primitive()))
  scanCenterId?: number;

  @serializable(alias("scan_center_name", primitive()))
  scanCenterName?: string;

  @serializable(alias("view_access_ids", list(primitive())))
  viewAccessIds?: number[];

  @serializable(alias("age", primitive()))
  age?: string;

  @serializable(alias("gender", primitive()))
  gender?: string;

  @serializable(alias("current_clinical_problem", primitive()))
  currentClinicalProblem?: string;

  @serializable(alias("additional_details", primitive()))
  additionalDetails?: string;

  @serializable(alias("referring_doctor", primitive()))
  referringDoctor?: string;

  @serializable(alias("patient_history", primitive()))
  patientHistory?: string;

  @serializable(alias("contrast", primitive()))
  contrast?: string;

  @serializable(
    alias(
      "zip_file",
      custom(
        (file) => file,
        (json) => {}
      )
    )
  )
  zipFile?: File;

  @serializable(alias("status", primitive()))
  status?: string;

  @serializable(alias("case_status", primitive()))
  caseStatus?: string;

  @serializable(alias("upload_status", primitive()))
  uploadStatus?: string;

  selfAssign: boolean = false;

  @serializable(alias("assigned_to_doctor_id", primitive()))
  assignedToDoctorId?: string;

  @serializable(alias("assigned_to_admin_id", primitive()))
  assignedToAdminId?: string;

  caseBodyPartIds: number[] = [];

  @serializable(alias("case_body_parts", list(object(CaseBodyPart))))
  caseBodyParts?: CaseBodyPart[] = [];
  static id: string | number | boolean | undefined;

  getFileName() {
    const bodyParts = this.caseBodyParts
      ?.map((caseBodyPart) => caseBodyPart?.bodyPartName)
      .join("_");
    return this.patientName + "_" + this.scanTypeName + "_" + bodyParts;
  }
}
