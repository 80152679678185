import React, { useEffect, useRef, useState } from "react";
import "./guestViewerWrapper.scss";
import { useParams } from "react-router-dom";
import { AuthReducerProps } from "../../../store/reducers/authReducer";
import { debug } from "console";

interface GuestViewerWrapperProps extends AuthReducerProps {}

function GuestViewerWrapper({ userRole }: GuestViewerWrapperProps) {
  const { caseId }: any = useParams();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const url: string | undefined = process.env.REACT_APP_VIEWER_BASE_URL ?? "";

  const authToken = localStorage.getItem("Authorization") || null;

  return (
    <div className={`viewer-wrapper`}>
      <div className="viewer-wrapper__iframe-wrapper">
        <iframe
          ref={iframeRef}
          src={`${url}?caseId=${caseId}&authToken=${encodeURIComponent(
            authToken || ""
          )}&guest=true`}
          title="Case viewer"
          className="viewer-wrapper__iframe"
        />
      </div>
    </div>
  );
}

export default GuestViewerWrapper;
