import { serializable, alias, primitive } from "serializr";

export class Attachment {
  @serializable(alias("id", primitive()))
  id?: number;

  @serializable(alias("image_url", primitive()))
  imageUrl?: string;

  @serializable(alias("image_signed_url", primitive()))
  imageSignedUrl?: string;

  @serializable(alias("file_name", primitive()))
  fileName?: string;
}
