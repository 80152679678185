import moment from "moment";
import { CaseDetail } from "../../models/CaseDetail/caseDetail.model";
import { BodyPartTypeEnum } from "../../enums/BodyPartType.enum";

export const stopPropogateEvent = (e: any) => e.stopPropagation();

export const copyCaseDetailsToClipboard = async (
  caseDetail: CaseDetail,
  targetUrl: string
) => {
  const { scanCenterName, patientName, scanTypeName, createdAt } = caseDetail;
  const bodyParts = (caseDetail?.caseBodyParts ? caseDetail?.caseBodyParts : [])
    .map((bodyPart) => bodyPart.bodyPartName)
    .join(",");
  const studyName = `${scanTypeName}${
    bodyParts !== BodyPartTypeEnum.NOT_SET ? "-" + bodyParts : ""
  }`;
  const text = `
Dear Sir/Mam,  

Warm greetings from ${scanCenterName}.  

Please find the patient details below:  

Patient Name: ${patientName}  
Study Type: ${studyName}  
Date: ${moment(createdAt).format("DD/MM/YYYY")}  

To view the images, click on the following link:  
${targetUrl}`;

  setTimeout(() => {
    navigator.clipboard.writeText(text);
  }, 0);
};
