import React, { useEffect, useState, useRef } from "react";
import { CaseDetail } from "../../../models/CaseDetail/caseDetail.model";
import AdminCaseService from "../../../services/Case/AdminCase/adminCase.service";
import SharedCaseService from "../../../services/Shared/shared.service";
import { CaseStatusEnum } from "../../../enums/caseStatus.enum";
import moment from "moment";
import { Button, Drawer, Menu, Modal, Popover, Table, Tooltip } from "antd";
import AppLoader from "../../../shared/components/AppLoader";
import AppCard from "../../../shared/components/AppCard";
import {
  EyeOutlined,
  DownloadOutlined,
  UserAddOutlined,
  FormOutlined,
  CloudDownloadOutlined,
  EllipsisOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import CaseAssignmentForm from "./CaseAssignmentForm";
import {
  copyCaseDetailsToClipboard,
  stopPropogateEvent,
} from "../../../shared/utils/eventUtils";
import ViewAccessForm from "./ViewAccessForm";
import CaseOverview from "../../../shared/components/CaseOverview";
import "./adminCases.scss";
import CaseFilterForm from "../../../shared/components/CaseFilterForm";
import { CaseFilterParams } from "../../../models/CaseFilterParams/caseFilterParams.model";
import { CaseBodyPart } from "../../../models/CaseBodyPart/caseBodyPart.model";
import ReportForm from "../../../shared/components/ReportForm";
import { Admin } from "../../../models/Admin/admin.model";
import ProfileService from "../../../services/Profile/profile.service";
import { UserRoleEnum } from "../../../enums/userRole.enum";
import ReportService from "../../../services/Report/report.service";
import { DownloadFileTypeEnum } from "../../../enums/downloadFileType.enum";
import { formatStatus } from "../../../shared/utils/dataFormatConverter";
import { generatePath, Link, useHistory } from "react-router-dom";
import { AppRoutes } from "../../../routes/routeConstants/appRoutes";
import CaseContainer from "../../../store/container/CaseContainer";
import { CaseReducerProps } from "../../../store/reducers/caseReducer";
import { useLocation } from "react-router-dom";
import { BodyPartTypeEnum } from "../../../enums/BodyPartType.enum";
import Notification from "../../../shared/components/Notification";
import { NotificationTypes } from "../../../enums/notificationTypes";

interface AdminCasesProps extends CaseReducerProps {}

function AdminCases({ newCaseDetail, updatedCaseDetail }: AdminCasesProps) {
  const location = useLocation();

  const [caseDetails, setCaseDetails] = useState<CaseDetail[]>([]);

  const [activeCase, setActiveCase] = useState<CaseDetail | undefined>();

  const [loading, setLoading] = useState(false);

  const [downloadLoading, setDownloadLoading] = useState(false);

  const [downloadZipLoading, setDownloadZipLoading] = useState(false);

  const [showCaseOverview, setShowCaseOverview] = useState(false);

  const [showReportForm, setShowReportForm] = useState(false);

  const [me, setMe] = useState<Admin>();

  const handleToggleReportForm = () => setShowReportForm(!showReportForm);

  const assignmentPopoverRef = useRef<any>(null);

  const viewPopoverRef = useRef<any>(null);

  const [formValues, setFormValues] = useState<CaseFilterParams>(
    Object.assign(new CaseFilterParams(), {
      ...new CaseFilterParams(),
      fromDate: moment().format("YYYY-MM-DD"),
      toDate: moment().format("YYYY-MM-DD"),
      scanCenterBranchId: undefined,
    })
  );

  const debounceTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleSubmit = (values: CaseFilterParams) => {
    const formValues = Object.assign(new CaseFilterParams(), values);
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleOnChange = (updatedValues: CaseFilterParams) => {
    // Update the form values with new inputs
    const newFormValues = Object.assign(new CaseFilterParams(), updatedValues);
    setFormValues(newFormValues);

    // Clear any existing debounce timer
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    // Set a new debounce timer
    debounceTimerRef.current = setTimeout(() => {
      // handleFetchCases(newFormValues); // Call the fetch function with the updated form values
    }, 500); // 500ms debounce delay
  };

  // Cleanup on component unmount to clear the debounce timer
  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  const handleReset = () => {
    const formValues = new CaseFilterParams();
    setFormValues(formValues);
    handleFetchCases(formValues);
  };

  const handleCloseCaseOverview = () => {
    setActiveCase(undefined);
    setShowCaseOverview(false);
  };

  const handleViewAccessSuccess = () => {
    if (viewPopoverRef?.current && viewPopoverRef.current) {
      viewPopoverRef.current.setPopupVisible(false);
    }
    setActiveCase(undefined);
  };

  const history = useHistory();

  const handleRowChange = (caseDetail: CaseDetail, _: number | undefined) => {
    return {
      onClick: (event: any) => {
        event.preventDefault(); // Prevent default behavior if needed
        if (caseDetail?.id) {
          history.push(
            generatePath(AppRoutes.CASE_DETAIL, {
              caseId: caseDetail.id,
            }),
            location
          );
        }
      },
    };
  };

  const getInitialFiltersFromURL = () => {
    const searchParams = new URLSearchParams(location.search);
    const urlFilters: Partial<CaseFilterParams> = {};

    const paramParsers: Record<string, (value: string) => any> = {
      patientName: (v) => v,
      scanCenterId: (v) => (v ? Number(v) : undefined),
      doctorId: (v) => (v ? Number(v) : undefined),
      scanTypeId: (v) => (v ? Number(v) : undefined),
      bodyPartId: (v) => (v ? Number(v) : undefined),
      status: (v) => v,
      fromDate: (v) => v,
      toDate: (v) => v,
    };

    Object.keys(paramParsers).forEach((key) => {
      const value = searchParams.get(key);
      if (value !== null) {
        urlFilters[key as keyof CaseFilterParams] = paramParsers[key](value);
      }
    });

    const initialFilters = new CaseFilterParams();

    // If fromDate is not in URL, set it to today's date
    if (!urlFilters.fromDate) {
      // Assuming you want the date in YYYY-MM-DD format
      urlFilters.fromDate = moment().format("YYYY-MM-DD");
    }

    // If toDate is not in URL, set it to today's date
    if (!urlFilters.toDate) {
      // Assuming you want the date in YYYY-MM-DD format
      urlFilters.toDate = moment().format("YYYY-MM-DD");
    }

    return Object.assign(initialFilters, urlFilters);
  };

  // Update URL with current filter values
  const updateUrlParams = (values: CaseFilterParams) => {
    const searchParams = new URLSearchParams();

    // Add non-empty filter values to URL
    Object.entries(values).forEach(([key, value]) => {
      if (value !== undefined && value !== null && value !== "") {
        searchParams.set(key, String(value));
      }
    });

    // Update URL without page reload
    history.replace(`${location.pathname}?${searchParams.toString()}`);
  };

  const handleFetchCases = (formValues: CaseFilterParams) => {
    setLoading(true);
    // Update URL params before fetching
    updateUrlParams(formValues);

    AdminCaseService.fetchAdminCases(
      formValues,
      (caseDetails: CaseDetail[]) => {
        setCaseDetails(caseDetails);
      },
      () => {},
      () => {
        setLoading(false);
      }
    );
  };

  const handleAssignmentSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    }
    setCaseDetails([...caseDetails]);
    if (assignmentPopoverRef?.current && assignmentPopoverRef.current) {
      assignmentPopoverRef.current.setPopupVisible(false);
    }
  };

  const handleCaseUpdateSuccess = (caseDetail: CaseDetail) => {
    const caseIndex = caseDetails.findIndex(
      (caseItem) => caseItem.id === caseDetail.id
    );
    if (caseIndex >= 0) {
      caseDetails[caseIndex] = caseDetail;
    }
    setCaseDetails([...caseDetails]);
    setActiveCase(caseDetail);
  };

  const handleDownloadReport = (
    caseDetail: CaseDetail,
    fileType: DownloadFileTypeEnum,
    withLetterHead: boolean
  ) => {
    setActiveCase(caseDetail);
    setDownloadLoading(true);
    if (caseDetail?.id) {
      const apiMethod = ReportService.downloadReport;
      apiMethod(
        fileType,
        caseDetail,
        withLetterHead,
        (reportUrl: string) => {
          window.open(reportUrl, "_blank");
        },
        () => {},
        () => {
          setActiveCase(undefined);
          setDownloadLoading(false);
        }
      );
    }
  };

  const handleDownloadDetails = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      setDownloadZipLoading(true);
      AdminCaseService.downloadAdminCase(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          window.open(targetUrl, "_blank");
        },
        () => {},
        () => {
          setDownloadZipLoading(false);
        }
      );
    }
  };

  const handleCaseShareLink = (caseDetail: CaseDetail) => {
    setActiveCase(caseDetail);
    if (caseDetail?.id) {
      SharedCaseService.getCaseShareLink(
        Number(caseDetail?.id),
        (targetUrl: any) => {
          copyCaseDetailsToClipboard(caseDetail, targetUrl);
        },
        () => {},
        () => {}
      );
    }
  };

  // Initial data fetch with URL parameters
  useEffect(() => {
    const initialFilters = getInitialFiltersFromURL();
    setFormValues(initialFilters);
    handleFetchCases(initialFilters);

    ProfileService.getProfile(
      UserRoleEnum.ADMIN,
      (admin: Admin) => {
        setMe(admin);
      },
      () => {},
      () => {}
    );
  }, [location.search]); // Re-fetch when URL changes

  useEffect(() => {
    if (newCaseDetail) {
      caseDetails.unshift(newCaseDetail);
      setCaseDetails([...caseDetails]);
    }
  }, [newCaseDetail]);

  useEffect(() => {
    if (updatedCaseDetail) {
      const caseIndex = caseDetails.findIndex(
        (caseDetail) => caseDetail.id === updatedCaseDetail.id
      );
      if (caseIndex >= 0) {
        caseDetails[caseIndex] = updatedCaseDetail;
      } else {
        caseDetails.unshift(updatedCaseDetail);
      }
      setCaseDetails([...caseDetails]);
    }
  }, [updatedCaseDetail]);

  const columns = [
    {
      title: "Patient Name",
      dataIndex: "patientName",
      key: "patientName",
      sorter: (a: any, b: any) => a.patientName.localeCompare(b.patientName),
      render: (patientName: string) => (
        <div className="admin-cases__patient-name">{patientName}</div>
      ),
    },
    {
      title: "Scan Center",
      dataIndex: "scanCenterName",
      key: "scanCenterName",
      sorter: (a: any, b: any) =>
        a.scanCenterName.localeCompare(b.scanCenterName),
    },
    {
      title: "Date Uploaded",
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: (a: any, b: any) => a.createdAt.localeCompare(b.createdAt),

      render: (date: string) => (
        <span>{date ? moment(date).format("DD-MMM-YYYY hh:mm A") : "NA"}</span>
      ),
    },
    {
      title: "Scan Type",
      dataIndex: "scanTypeName",
      key: "scanTypeName",
      sorter: (a: any, b: any) => a.scanTypeName.localeCompare(b.scanTypeName),
    },
    {
      title: "Body parts",
      dataIndex: "caseBodyParts",
      key: "caseBodyParts",
      render: (caseBodyParts: CaseBodyPart[]) => (
        <div>
          {caseBodyParts.map((bodyPart) => bodyPart.bodyPartName).join(", ")}
        </div>
      ),
    },
    {
      title: "Assigned Doctor",
      dataIndex: "assignedToDoctorName",
      key: "assignedToDoctorName",
      sorter: (a: any, b: any) => {
        // Handle undefined or null values
        const nameA = a.assignedToDoctorName || "";
        const nameB = b.assignedToDoctorName || "";

        return nameA.localeCompare(nameB);
      },
      render: (doctorName: string) => <span>{doctorName || "NA"}</span>,
    },
    {
      title: "Due Time",
      dataIndex: "dueDate",
      key: "dueDate",
      render: (time: string, caseDetail: CaseDetail) =>
        caseDetail?.status !== CaseStatusEnum.REPORTED ? time : null,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.localeCompare(b.status),
      render: (status: string, caseDetail: CaseDetail) => {
        const showGenerateReport =
          caseDetail?.status === CaseStatusEnum.TO_BE_APPROVED ||
          (caseDetail?.status === CaseStatusEnum.ASSIGNED &&
            me?.id === caseDetail?.assignedToAdminId);
        return (
          <div
            className="admin-cases__status-wrapper"
            onClick={stopPropogateEvent}
          >
            <span className={`admin-cases__status ${status}`}>
              {formatStatus(status)}
            </span>
            {caseDetail?.status !== CaseStatusEnum.VIEW && (
              <Tooltip title="Assign Case">
                <Popover
                  ref={assignmentPopoverRef}
                  destroyTooltipOnHide
                  overlayClassName="admin-cases__assignment-popover"
                  placement="bottomLeft"
                  content={
                    <div>
                      <CaseAssignmentForm
                        caseDetail={caseDetail}
                        onSuccess={handleAssignmentSuccess}
                      />
                    </div>
                  }
                  title="Assign case"
                  trigger="click"
                >
                  <Button icon={<UserAddOutlined />} className="ml-2" />
                </Popover>
              </Tooltip>
            )}
            {/* To be used for future use
            {caseDetail?.status !== CaseStatusEnum.UNASSIGNED &&
              caseDetail?.status !== CaseStatusEnum.VIEW && (
                <Tooltip title="View Access">
                  <Popover
                  ref={viewPopoverRef}
                    destroyTooltipOnHide
                    overlayClassName="admin-cases__assignment-popover"
                    placement="bottomLeft"
                    content={
                      <div>
                        <ViewAccessForm
                          caseDetail={caseDetail}
                          onSuccess={handleViewAccessSuccess}
                        />
                      </div>
                    }
                    title="Provide view access"
                    trigger="click"
                  >
                    <Button icon={<EyeOutlined />} className="ml-2" />
                  </Popover>
                </Tooltip>
              )} 
            To be used for future use*/}
            {showGenerateReport && (
              <Tooltip title="Generate Report">
                <Button
                  icon={<FormOutlined />}
                  className="ml-2"
                  onClick={() => {
                    setActiveCase(caseDetail);
                    handleToggleReportForm();
                  }}
                />
              </Tooltip>
            )}
            {caseDetail?.status === CaseStatusEnum.REPORTED && (
              <Tooltip title="Download Report">
                <Popover
                  destroyTooltipOnHide
                  overlayClassName="admin-cases__assignment-popover"
                  placement="bottomLeft"
                  content={
                    <div>
                      <Menu mode="vertical" className="border-none">
                        <Menu.Item
                          className="navbar-item m-0"
                          key="with_letterhead"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              true
                            );
                          }}
                        >
                          Download with Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="without_letterhead"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.PDF,
                              false
                            );
                          }}
                        >
                          Download without Letterhead
                        </Menu.Item>
                        <Menu.Item
                          className="navbar-item m-0"
                          key="as_word"
                          onClick={() => {
                            handleDownloadReport(
                              caseDetail,
                              DownloadFileTypeEnum.WORD,
                              false
                            );
                          }}
                        >
                          Download as Word
                        </Menu.Item>
                      </Menu>
                    </div>
                  }
                  title="Download Report"
                  trigger="click"
                >
                  <Button
                    loading={
                      activeCase?.id === caseDetail?.id && downloadLoading
                    }
                    icon={<DownloadOutlined />}
                    className="ml-2"
                  />
                </Popover>
              </Tooltip>
            )}
            <Tooltip title="Download Case">
              <Button
                loading={
                  activeCase?.id === caseDetail?.id && downloadZipLoading
                }
                icon={<CloudDownloadOutlined />}
                className="ml-2"
                onClick={() => {
                  handleDownloadDetails(caseDetail);
                }}
              />
            </Tooltip>
            <Tooltip title="Copy Share Link">
              <Button
                icon={<CopyOutlined />}
                className="ml-2 clickable-icon"
                onClick={() => {
                  Notification({
                    message: "Copied",
                    type: NotificationTypes.SUCCESS,
                  });
                  handleCaseShareLink(caseDetail);
                }}
              />
            </Tooltip>
            <Tooltip title="More Case Details">
              <Button
                icon={<EllipsisOutlined />}
                className="ml-2"
                onClick={(event: any) => {
                  setActiveCase(caseDetail);
                  setShowCaseOverview(true);
                }}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <div className="admin-cases">
      <AppCard>
        <CaseFilterForm
          isAdmin
          formValues={formValues}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onChange={handleOnChange}
        />
        {loading ? (
          <AppLoader loading={loading} />
        ) : (
          <Table
            dataSource={caseDetails}
            rowClassName="cursor-pointer"
            onRow={handleRowChange}
            columns={columns}
          />
        )}
      </AppCard>
      <Modal
        destroyOnClose
        visible={showCaseOverview}
        className="primary-modal"
        onCancel={handleCloseCaseOverview}
        footer={null}
        maskClosable={false}
      >
        {activeCase?.id && (
          <CaseOverview
            isAdmin
            activeCaseId={activeCase?.id}
            onCancel={handleCloseCaseOverview}
            onUpdateSuccess={handleCaseUpdateSuccess}
          />
        )}
      </Modal>
      <Drawer
        destroyOnClose
        title={null}
        width="70%"
        onClose={handleToggleReportForm}
        visible={showReportForm}
        maskClosable={false}
        footer={null}
      >
        {activeCase?.id && (
          <ReportForm
            caseDetails={activeCase}
            caseDetailId={activeCase.id}
            onPublish={() => handleFetchCases(formValues)}
          />
        )}
      </Drawer>
    </div>
  );
}

export default CaseContainer(AdminCases);
